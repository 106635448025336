:root {
    --appGold: #FDBE00;
    --appBlack: #000000;
    --appWhite: #FFFFFF;
    --appGrey: #aeaeae;

    body {
        background-color: var(--appBlack);
        margin: 5px;
    }

    img {
        z-index: -1;
    }

    span {
        z-index: 1;
    }

    ul, li {
        font-size: 20px;
        list-style-type: none;
        margin-bottom: 4px;
        width: 100%;
    }

    .listFont {
        font-size: 32px;
    }

    .scenario {
        font-size: 24px;
        color: var(--appGrey);
        align-items: center;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    a {
        color: var(--appGrey);
        target: "_blank";
    }

    a:visited {
        color: var(--appGrey);
    }

    a:hover {
        color: var(--appGold);
    }

    .introBody {
        width: 80%;
    }

    .myButton, .creditsButton {
        color: var(--appGrey);
        background: var(--appBlack);
        border-width: 2px;
        border-color: var(--appWhite);
        border-style: solid;
        font-size: 20px;
    }

    .creditsButton {
        border-width: 0;
        font-size: 14px;
    }

    .myButton:hover, .creditsButton:hover {
        color: var(--appGold);
        border-color: var(--appGold);
    }

    .App {
        text-align: center;
        color: var(--appGold);
        font-size: 24px;
        background-color: var(--appBlack);
        z-index: 1;
    }

    .modal, .modalText, .outerModal {
        text-align: center;
        color: var(--appGold);
        font-size: 24px;
        background-color: var(--appBlack);
        border-style: solid;
    }

    .outerModal {
        border-style: none;
    }

    p {
        z-index: 1;
    }

    .footer {
        font-size: 18px;
        color: var(--appGold);
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .modalText, .modalHeaderText {
        font-size: 30px;
        border-style: none;
        text-align: left;
    }

    .modalText {
        color: var(--appGrey);
    }

    .bullet {
        color: var(--appGold);
        font-weight: bold;
    }

    .alert {
        color: red;
    }

    .good {
        color: green;
    }

    .modalHeaderText {
        font-weight: bold;
        text-align: center;
    }

    .titleText {
        align-items: center;
        text-align: center;
        vertical-align: center;
        font-size: 40px;
        width: 75%;
        font-weight: bold;
    }

    .pageText {
        align-items: center;
        text-align: center;
        vertical-align: center;
        font-size: 40px;
        font-weight: normal;
        width: 100%;
    }

    .pageHeader {
        vertical-align: center;
        width: 100%;
        background-color: var(--appBlack);
    }

    .headerTable {
        width: 100%;
        border-style: none;
    }

    .alignRight {
        text-align: right !important;
        align-items: flex-end !important;
    }

    .alignLeft {
        text-align: left !important;
    }

    .alignCenter {
        text-align: center !important;
    }

    .coin {
        height: 40px;
    }

    .splashImage, .scenarioImage {
        height: 50vmin;
        pointer-events: none;
        z-index: 0;
        position: page;
        justify-content: center;
    }

    .scenarioImage {
        height: 35vmin;
    }

    .thumbnailImage {
        height: 40px;
    }

    .modalImage {
        height: 15vmin;
        pointer-events: none;
        z-index: 0;
        position: page;
        #justify-content: center;
    }

    .App-body {
        background-color: var(--appBlack);
        min-height: 85vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: space-evenly;
        #justify-content: center;
        color: var(--appGold);
        z-index: 1;
        border-style: none;
    }

    .App-link {
        color: #61dafb;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .pulse {
        animation: pulse infinite 5s;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .growThenDisappear {
        animation: growThenDisappear 1 2s;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .buzz {
        animation: buzz infinite .1s;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .spin {
        animation: spin 5 .1s;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .flyIn {
        animation: flyInFromRight 1 .5s;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .jumpUp {
        animation: flyInFromRight 5 .5s;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .grow {
        animation: grow 1 .5s;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes buzz {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(5deg);
    }
}

@keyframes jumpUp {

    0% {
        display: block;
        transform: translateY(100%)
    }

    100% {
        transform: translateY(0%)
    }
}

@keyframes flyInFromRight {

    0% {
        display: block;
        transform: translateX(100%)
    }

    100% {
        transform: translateX(0%)
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    50% {
        transform: scale(1.5);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@keyframes growThenDisappear {
    0% {
        transform: scale(.1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    50% {
        transform: scale(1.0);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(.1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@keyframes grow {
    0% {
        transform: scale(.01);

    }

    100% {
        transform: scale(1);
    }
}

